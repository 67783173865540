import React from "react"
import { Helmet } from "react-helmet"
import { injectIntl } from "gatsby-plugin-intl"

import Visual from "@images/metas/meta_visual.jpg"

function Metatag({ intl, href, origin }) {
  
  const TITLE = intl.formatMessage({ id: "meta_title" })
  const DESCRIPTION = intl.formatMessage({ id: "meta_description" })
  const IMAGE = `https://www.laboservices.eu/${Visual}`
  const URL = href

  return (
    <Helmet
      htmlAttributes={{
        lang: intl?.locale,
      }}
      title={TITLE}
      meta={[
        {
          name: `google-site-verification`,
          content: `ErEvic311uKUYtV3ip_FbrH1vExNAvgVqqQQPtTIejQ`,
        },
        {
          name: `description`,
          content: DESCRIPTION,
        },
        {
          property: `og:title`,
          content: TITLE,
        },
        {
          property: `og:description`,
          content: DESCRIPTION,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: IMAGE,
        },
        {
          property: `og:url`,
          content: URL,
        },
        {
          property: `twitter:card`,
          content: `summary`,
        },
        {
          property: `twitter:url`,
          content: URL,
        },
        {
          property: `twitter:title`,
          content: TITLE,
        },
        {
          property: `twitter:description`,
          content: DESCRIPTION,
        },
        {
          property: `twitter:image`,
          content: IMAGE,
        },
      ]}
    />
  )
}

export default injectIntl(Metatag)
